<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data"> </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('create', 'freefire-th')"
              >
                {{ $t('button.saveChanges') }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t('button.back') }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardHeader,
  BCardTitle,
  BLink,
  BFormRadioGroup,
  BFormCheckbox,
  BTab,
  BTabs,
  BFormTextarea,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInput from '@/components/NInput'
import NTable from '@/components/NTable'
import formInput from './formInput'
import PermissionTable from '../role/PermissionTable.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

const PackageRepository = Repository.get('package')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NTable,
    BFormCheckbox,
    PermissionTable,
    BTabs,
    BTab,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        isEnable: true,
        nameEn: null,
        nameKm: null,
        descriptionEn: null,
        descriptionKm: null,
      },
      permissions: [],
      loading: false,
      permissionLoading: false,
      required,
    }
  },
  mounted() {},
  methods: {
    submit() {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.loading = true

          PackageRepository.create({ ...this.data, game_type_id: 14 })
            .then((response) => {
              this.$router.push({
                name: 'view-freefire-th-package',
                params: { id: response.data.data.id },
              })
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.createForm.setErrors(error.response?.data?.message)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = [...formInput]
    return { fields }
  },
}
</script>
